.body {
  padding: 20px;
  margin: 60px;
  background-color: hsl(218, 14%, 11%,0.7);
  border-radius: 20px;
  border: 1px solid hsl(222, 18%, 61%);
  .container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: start;

    h1 {
      font-size: 36px;
      margin-bottom: 20px;
      color: #0099ff;
    }

    h2 {
      font-size: 28px;
      margin-bottom: 20px;
      color: #555;
    }

    .line {
      width: 98%;
      height: 1px;
      background-color: #4e4d4d;
      margin: 20px auto;
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 20px;
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 5px;

      a {
        color: #0099ff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
      
        }
      }

      svg {
        width: 24px;
        height: 24px;
        fill: #e5ff00;
      }
    }
  }

  h1 {
    font-size: 30px;
    margin-top: 40px;
    color: #0099ff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    svg {
      width: 32px;
      height: 32px;
      fill: #0099ff;
    }
  }

  .tech-cards,
  .net-cards,
  .fun-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 20px 0;

    li {
      background-color: #0099ff;
      color: #fff;
      padding: 10px 20px;
      border-radius: 5px;
      font-size: 16px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #007acc;
      }
    }
  }

  .f-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 100%;
    max-width: 300px;
    padding: 20px;
    background-color: #0099ff;
    color: #fff;
    border-radius: 10px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #007acc;
    }
  }

  // Media Queries
  @media (max-width: 1200px) {
    padding: 20px;
    margin:  0 50px;
    border-radius: 0 0 20px 20px;
    border-top: none;

    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }

    .line {
      width: 90%;
    }

    .tech-cards,
    .net-cards,
    .fun-cards {
      li {
        padding: 8px 16px;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 576px) {
    padding: 15px;
    margin: 20px 15px;
    border-radius: 12px;
    border:1px solid #0099ff;

    h1 {
      font-size: 22px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }

    .line {
      width: 100%;
    }

    .tech-cards,
    .net-cards,
    .fun-cards {
      align-items: center;
      li {
        max-width: 200px;
        padding: 8px 12px;
        font-size: 12px;
      }
    }
  }
}
