// src/styles/Photos.scss
.photos-body {
  padding: 20px;
  margin: 60px;
  background-color: hsl(218, 14%, 11%, 0.7);
  border-radius: 20px;
  border: 1px solid hsl(222, 18%, 61%);

  .container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: start;

    h1 {
      font-size: 36px;
      margin-bottom: 20px;
      color: #0099ff;
    }

    .line {
      width: 98%;
      height: 1px;
      background-color: #4e4d4d;
      margin: 20px auto;
    }

    .spinner, .error-message, .no-photos-message {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      font-size: 18px;
      color: #ff0000;
    }

    .spinner-icon {
      font-size: 48px;
      color: #0099ff;
      animation: spin 1s linear infinite;
    }

    .photos-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .photo-card {
        background-color: #0099ff;
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        width: calc(33.333% - 20px);
        box-sizing: border-box;
        transition: background-color 0.3s ease;

        img {
          width: 100%;
          border-radius: 10px;
        }

        p {
          margin-top: 10px;
          font-size: 16px;
        }

        &:hover {
          background-color: #007acc;
        }
      }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  // Media Queries
  @media (max-width: 1200px) {
    padding: 20px;
    margin: 0 50px;
    border-radius: 0 0 20px 20px;
    border-top: none;

    h1 {
      font-size: 28px;
    }

    .photos-grid {
      .photo-card {
        width: calc(50% - 20px);
      }
    }
  }

  @media (max-width: 576px) {
    padding: 15px;
    margin: 20px 15px;
    border-radius: 12px;
    border: 1px solid #0099ff;

    h1 {
      font-size: 22px;
    }

    .photos-grid {
      .photo-card {
        width: calc(100% - 20px);
      }
    }
  }
}
