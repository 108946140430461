// src/assets/styles/globals.scss

// CSS Reset (You can use a popular reset or normalize.css)
// Example: Eric Meyer's Reset CSS
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Base body styles
body {
  font-family: 'Arial, sans-serif';
  line-height: 1.6;
  background-color: hsl(0, 0%, 7%);
  min-width: 90vw;
  overflow-x: hidden;
  color: #fff;
}

// Links
a {
  color: #27ae60;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.home{
  display: flex;
  flex-direction: row;
}

.home-container {
  display: flex;
  flex-direction: column-reverse;
}

@media (max-width: 1200px) {
.home{
  display: flex;
  flex-direction: column;
}
  .home-container {
    flex-direction: column-reverse;
  }
}

@media (max-width: 569px) {
  .home{
    display: flex;
    flex-direction: column;
  }
  .home-container {
    flex-direction: column;
  }
}
