@import './animations';

.content {
  &.fade-enter {
    opacity: 0;
    transform: translateY(20px);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s, transform 0.5s;
  }
  &.fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s;
  }
}
.links {
  padding: 20px;
  margin: 60px;
  background-color: hsl(218, 14%, 11%);
  border-radius: 20px 20px 0 0;
  border: 1px solid hsl(221, 15%, 22%);

  .nav-link {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 20px;

    li {
      color: #fff;
      font-size: 25px;
      transition: color 0.3s ease;

      &:hover {
        color: #0099ff;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 1200px) {
    margin: 0 50px;
    background-color: hsl(218, 14%, 11%,0.7);
  border: 1px solid hsl(222, 18%, 61%);
    border-bottom: none ;
    .nav-link {
      gap: 15px;
      justify-content: end;


      li {
        font-size: 23px;
        color: #b3ff02;
        transition: all 0.5s ease-in-out;
        font-weight: bold;
        &:hover {
          color: #0099ff;
          cursor: pointer;
          border-bottom: 1px solid #0099ff;
        }
      }
    }
  }

  @media (max-width: 576px) {
    background-color: hsl(218, 14%, 11%,);
    position: fixed;
    bottom: 0;
    min-width: 98vw;
    overflow-x: hidden;
    margin: 20px 0 0 0;
    gap: 7px;
    justify-content: start;
    text-align: center;
    .nav-link {
      li {
        font-size: 16px;
       color: #ffffff;
      }
    }
  }
}
