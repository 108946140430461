// src/styles/About.scss
.about-body {
  padding: 20px;
  margin: 60px;
  background-color: hsl(218, 14%, 11%, 0.7);
  border-radius: 20px;
  border: 1px solid hsl(222, 18%, 61%);
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: start;

    h1 {
      font-size: 36px;
      margin-bottom: 20px;
      color: #0099ff;
    }

    .line {
      width: 98%;
      height: 1px;
      background-color: #4e4d4d;
      margin: 20px auto;
    }

    .about-section {
      margin-bottom: 20px;

      h2 {
        font-size: 28px;
        margin-bottom: 10px;
        color: #555;
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
          width: 24px;
          height: 24px;
          fill: #0099ff;
        }
      }

      p {
        font-size: 18px;
        line-height: 1.6;
      }
    }
  }

  // Media Queries
  @media (max-width: 1200px) {
    padding: 20px;
    margin: 0 50px;
    border-radius: 0 0 20px 20px;
    border-top: none;

    h1 {
      font-size: 28px;
    }

    .about-section h2 {
      font-size: 24px;
    }

    .about-section p {
      font-size: 16px;
    }
  }

  @media (max-width: 576px) {
    padding: 15px;
    margin: 20px 15px;
    border-radius: 12px;
    border: 1px solid #0099ff;

    h1 {
      font-size: 22px;
    }

    .about-section h2 {
      font-size: 20px;
    }

    .about-section p {
      font-size: 14px;
    }

    .line {
      width: 100%;
    }
  }
}
