// src/styles/NotFound.scss
.not-found-body {
  padding: 20px;
  margin: 60px;
  background-color: hsl(218, 14%, 11%, 0.7);
  border-radius: 20px;
  border: 1px solid hsl(222, 18%, 61%);
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;

    h1 {
      font-size: 36px;
      margin-bottom: 20px;
      color: #ff0000;
    }

    .line {
      width: 98%;
      height: 1px;
      background-color: #4e4d4d;
      margin: 20px auto;
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 20px;
      color: #fff;
    }

    .back-home {
      display: inline-block;
      padding: 10px 20px;
      background-color: #0099ff;
      color: #fff;
      text-decoration: none;
      border-radius: 5px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #007acc;
      }
    }
  }

  // Media Queries
  @media (max-width: 1200px) {
    padding: 20px;
    margin: 0 50px;
    border-radius: 0 0 20px 20px;
    border-top: none;

    h1 {
      font-size: 28px;
    }

    p {
      font-size: 16px;
    }
  }

  @media (max-width: 576px) {
    padding: 15px;
    margin: 20px 15px;
    border-radius: 12px;
    border: 1px solid #0099ff;

    h1 {
      font-size: 22px;
    }

    p {
      font-size: 14px;
    }

    .line {
      width: 100%;
    }
  }
}
